require('cookieconsent');
import Lang from 'lang.js';

var lang = new Lang({
    messages: {
        'lt.cookies': {
            'message': 'Siekdami pagerinti Jūsų naršymo kokybę, statistiniais ir rinkodaros tikslais šioje svetainėje naudojame slapukus (angl. Cookies), kuriuos galite bet kada atšaukti pakeisdami savo interneto naršyklės nustatymus ir ištrindami įrašytus slapukus.',
            'allow': 'Sutinku',
            'deny': 'Nesutinku',
            'link': 'Privatumo politika',
            'href': '/lt/privacy-policy',
        },
        'en.cookies': {
            'message': 'In order to improve the quality of your browsing, we use cookies on this website for statistical and marketing purposes, which you can cancel at any time by changing the settings of your internet browser and deleting the saved cookies.',
            'allow': 'Agree',
            'deny': 'Disagree',
            'link': 'Privacy Policy',
            'href': '/privacy-policy',
        }
    }
});

lang.setLocale($('meta[name="selected-language"]').attr('content'));

// Cookies
function deleteCookies() {
  var theCookies = document.cookie.split(';');

  for (var i = 0 ; i < theCookies.length; i++) {
    document.cookie = theCookies[i].split('=')[0] + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
window.addEventListener('load', function() {
  window.cookieconsent.initialise({

    'palette': {
      'popup': {
        'background': '#1b294b'
      },
      'button': {
        'background': 'transparent',
        'border': '#ffffff',
        'text': '#ffffff'
      }
    },
    'position': 'bottom-left',
    'type': 'opt-out',
    'content': {
      'message': lang.get('cookies.message'),
      'allow': lang.get('cookies.allow'),
      'deny': lang.get('cookies.deny'),
      'link': lang.get('cookies.link'),
      'href': lang.get('cookies.href')
    },
    onInitialise: function (status) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == 'opt-in' && didConsent) {
      }
      if (type == 'opt-out' && !didConsent) {
        deleteCookies();
      }
    },
    onStatusChange: function(status, chosenBefore) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == 'opt-in' && didConsent) {
      }
      if (type == 'opt-out' && !didConsent) {
        deleteCookies();
      }
    },
    onRevokeChoice: function() {
      var type = this.options.type;
      if (type == 'opt-in') {
        deleteCookies();
      }
      if (type == 'opt-out') {
      }
    },
  })
});

// Global Imports
window.jQuery = window.$ = require('jquery');
window.boostrap = require('bootstrap');
window.boostrapTable = require('bootstrap-table');

// Require custom files
require('./swipers.js');
require('./navbar.js');
require('./timeline.js');
require('./validation.js');
// require('./maps.js');
require('./forms.js');
require('./cookies.js');

function timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

$(document).on("click", ".js--pay-button", function(e){
  e.preventDefault();

  var invoice_id = $(this).attr('data-id');

  $.ajax({
    type: 'GET',
    url: '/c/ajax/invoice/' + invoice_id,
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    // data: $('.js--cart-form').serialize(),
    success: function (response) {

      var invoice = response.invoice;

      $('.js--get-invoice_id').val(invoice.id);
      $('.js--get-stripe-invoice_id').val(invoice.id);

    },
    error: function( response ) {
      $('.session-container').html('');
      $('.session-container').append('<div class="alert alert-danger w-100">Klaida: ' + data + '</div>');
    }
  });

});

$(document).on("click", ".js--action-link", function(e){
  e.preventDefault();

  var request_id = $(this).attr('data-id');
  var user_id = $(this).attr('data-user-id');
  $.ajax({
    type: 'GET',
    url: '/c/ajax/preview-modal/' + request_id,

    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    // data: $('.js--cart-form').serialize(),
    // beforeSend: function() {
    //     $("#loaderDiv").show();
    // },
    success: function (response) {

      var request = response.request;
      var userID = response.auth_user_id;


      $('#previewRequestModal .modal-title').text(request.request_number);
      $('#previewRequestModal .requestID').val(request_id);
      $('#previewRequestModal .userID').val(user_id);
      $('#previewRequestModal .modal-request-comments-chat').html('');

      var requestFile = request.request_files;

      $('.attachments-list').html('');

      $.each(requestFile, function(key,val) {
        $('.attachments-list').append('<a href="/storage/' + val.full_path + '" download><li><img class="attachment-icon" src="/assets/vector/attachment-icon.svg" alit=""><span>' + val.filename + '</span></li></a>');
      });

      var requestMessages = request.get_messages;

      $.each(requestMessages, function(key,val) {
        var adminUser = val.user;

        var time = new Date(val.created_at).getTime();

        if (userID != val.user_id) {
          $('#previewRequestModal .modal-request-comments-chat').append('<div class="chat-bubble chat-bubble-dark chat-bubble-left"><div class="chat-bubble-avatar"><img class="chat-bubble-avatar-icon" src="/assets/vector/profile-user-icon.svg" alt=""></div><div class="chat-bubble-wrapper"><div class="chat-bubble-metadata"><div class="chat-bubble-customer-name">' + adminUser.name + '</div><div class="chat-bubble-timestamp">' + timeSince(time) + ' ago </div></div><div class="chat-bubble-message">' + val.content + '</div></div></div>');
        } else {
          $('#previewRequestModal .modal-request-comments-chat').append('<div class="chat-bubble chat-bubble-light chat-bubble-right"><div class="chat-bubble-wrapper"><div class="chat-bubble-metadata"><div class="chat-bubble-customer-name"></div><div class="chat-bubble-timestamp">'+ timeSince(time) + ' ago </div></div><div class="chat-bubble-message">' + val.content + '</div></div></div>');
        }
      });

    },
    error: function( response ) {
      $('.session-container').html('');
      $('.session-container').append('<div class="alert alert-danger w-100">Klaida: ' + data + '</div>');
    }
  });

});



$(".submitButton").click(function() {
  //Select the parent form and submit
  $(this).parent("form").submit();
});

$(document).on("click", ".js--contract-link", function(e){
  e.preventDefault();

  var contract_id = $(this).attr('data-id');

  $.ajax({
    type: 'GET',
    url: '/c/ajax/contract/' + contract_id,

    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    // data: $('.js--cart-form').serialize(),
    success: function (response) {

      var contract = response.contract;

      $('#signContractModal .contract-id').val(contract.id);

    },
    error: function( response ) {
      $('.session-container').html('');
      $('.session-container').append('<div class="alert alert-danger w-100">Klaida: ' + data + '</div>');
    }
  });

});

$(document).on("click", ".js--contract-upload", function(e){
  e.preventDefault();

  var contract_id = $(this).attr('data-id');

  $.ajax({
    type: 'GET',
    url: '/c/ajax/contract/upload/' + contract_id,

    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    // data: $('.js--cart-form').serialize(),
    success: function (response) {

      var contract = response.contract;

      $('#uploadContractModal .contract-id').val(contract.id);
    },
    error: function( response ) {
      $('.session-container').html('');
      $('.session-container').append('<div class="alert alert-danger w-100">Klaida: ' + data + '</div>');
    }
  });

});

$(document).on("keyup", "#uploadContractModal #form-field-phone-number", function(e){

  $('#uploadContractModal .btn-primary').attr('disabled', false);
});

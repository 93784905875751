import Swiper from 'swiper/bundle';

// Swipers
var swiper = new Swiper('.js--hero-swiper', {
    direction: 'vertical',
    centeredSlides: true,
    lazy: true,
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    keyboard: {
      enabled: true,
    },
    grabCursor: true,
    effect: 'coverflow',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
});

var swiper = new Swiper('.js--testimonials-swiper', {
    centeredSlides: true,
    autoHeight: true,
    lazy: true,
    loop: false,
    // speed: 1500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    grabCursor: true,
    // effect: 'fade',
    navigation: {
      nextEl: '.swiper-testimonials-button-next',
      prevEl: '.swiper-testimonials-button-prev',
    },
    pagination: {
      el: '.swiper-testimonials-pagination',
      type: 'fraction',
    },
});

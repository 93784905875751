import Dropzone from 'dropzone';

jQuery('.js--signup-checkboxes').on('click', function() {
  jQuery(this).parent().parent().find('input[type=checkbox]').attr('checked', 'checked');
});

// Dropzone
// TODO: accepted files for contracts
if ($('div.js--file-upload-dropzone')[0]) {
 const dropzone = new Dropzone('div.js--file-upload-dropzone', {
  maxFiles: 50,
  uploadMultiple: true,
  acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pages,.odt",
  autoProcessQueue: true,
  addRemoveLinks:   false,

  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  },
  url: '/ajax/upload-dropzone-files',
  success: function (file, response) {
    // TODO remove disabled tag from nearest form submit button
    console.log(`File added: ${file.name}`);
    console.log(file);
    console.log(response);
  }
 });
}


if ($('div.js--file-upload-dropzone-contract')[0]) {
 const dropzone2 = new Dropzone('div.js--file-upload-dropzone-contract', {
  maxFiles: 50,
  uploadMultiple: true,
  acceptedFiles: "application/pdf",
  autoProcessQueue: true,
  addRemoveLinks:   false,

  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  },
  url: '/ajax/upload-contract-dropzone-files',
  success: function (file, response) {
    // TODO remove disabled tag from nearest form submit button
    console.log(`File added: ${file.name}`);
    console.log(file);
    console.log(response);
  }
 });
}

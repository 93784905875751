require('jquery-validation');

// Validate forms
if (jQuery('form')[0]) {
    var forms = jQuery('form');
    for (var i = 0; i < forms.length; i++) {
      $(forms[i]).validate();
    }
}


$('button[type="submit"]').on('click', function(e) {

  if ($(this).parent().parent().find('form').valid() == true) {
      $(this).html('<div class="spinner-border" role="status"></div>');
      $(this).attr('disabled', 'disabled');
      $(this).parent().parent().find('form').submit();
  }

});
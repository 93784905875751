jQuery(document).ready(function($) {

  // Hamburgers
  $('.navbar-toggler').on('click', function() {

    if ( ! $('.navbar-toggler').attr('aria-expanded') == true ) {
      $('.hamburger').removeClass('is-active');
    }

    if ( $('.navbar-toggler').hasClass('collapsed') ) {
      $('.hamburger').removeClass('is-active');
    } else {
      $('.hamburger').addClass('is-active');
    }

  });

  // Scrollable header with animation
  if ($('.js--smart-scroll').length > 0) {
      var last_scroll_top = 0;

      $(window).on('scroll', function() {

          // Get scroll position
          var navbar        = $('.navbar');
          var scroll_top    = $(this).scrollTop();
          var navbar_height = $(navbar).outerHeight();

          // Show or hide menu depends on direction
          if(scroll_top < last_scroll_top) {

              $('.js--smart-scroll').removeClass('scrolled-down')
                                    .addClass('scrolled-up navbar-position-fixed');

                                    if (navbar.hasClass('navbar-dark')) {
                                      $('.js--smart-scroll').addClass('navbar-position-fixed-light');
                                    }

              $('body').css('padding-top', navbar_height);

          }
          else {

            if ( (scroll_top > navbar_height / 2) && ! navbar.hasClass('navbar-position-fixed')) {
              $('.js--smart-scroll').removeClass('scrolled-up navbar-position-fixed')
                                    .addClass('scrolled-down');

                                    if (navbar.hasClass('navbar-dark')) {
                                      $('.js--smart-scroll').removeClass('navbar-position-fixed-light');
                                    }

              $('body').css('padding-top', 0);
            }

          }

          // On initial position remove any new classes
          if(scroll_top == 0) {
            $('.js--smart-scroll').removeClass('navbar-position-fixed');
            $('body').css('padding-top', 0);
          }

          // Close mobile menu on scroll
          if ( (scroll_top - last_scroll_top) > navbar_height / 4) {
              $('.navbar-toggler').removeClass('is-active');
              $('.navbar-toggler').attr('area-expanded', false);
              $('.navbar-collapse').removeClass('show');
          }

          last_scroll_top = scroll_top;

      });

  }
  // END Scrollable header with animation

  // Scroll 1px top
  $( document ).ready(function() {
    if($(window).scrollTop() > $('.js--smart-scroll').outerHeight()) {
        $('.js--smart-scroll').addClass('scrolled-down');
    }
  });

});
